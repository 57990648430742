import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { States } from '@/helpers/common/states';
import api, { AxiosError } from '@/services/api';
import OrderSite from '@/model/OrderSite';
import { useDialog } from '@/hooks/useDialog';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import useForm from '@/hooks/useForm';
import SupportReverseFilter from '@/model/SupportReverseFilter';
import Page from '@/model/Page';
import { ReverseContainer } from './ui';
import { CancelContent } from '../../components/CancelContent';
import { FormInputName, ShouldShowModal } from '../../types';
import { ReverseContent } from '../../components/ReverseContent';

export const ReverseScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [currentPage, setCurrentPage] = useState<Page<SupportReverseFilter, OrderSite>>({
    page: 1,
    pageSize: 10,
    sort: 'orderDate',
    order: 'DESC',
  } as Page<SupportReverseFilter, OrderSite>);
  const [modal, setModal] = useState<ShouldShowModal>(ShouldShowModal.detail);
  const [order, setOrder] = useState<OrderSite>();
  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const confirmDelete = useConfirmDelete();

  const getOrders = async (page: Page<SupportReverseFilter, OrderSite>): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<Page<SupportReverseFilter, OrderSite>>(
        '/support/reverse',
        page,
      );

      if (data) {
        setCurrentPage(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnPaginationChange = async (page: number): Promise<void> => {
    getOrders({
      ...currentPage,
      page,
    });
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeFormInput: onChangeFormInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
  });

  const handleOnShouldShowModal = async (
    value: ShouldShowModal,
    newTitleModal: string | React.ReactNode,
    orderSelected?: OrderSite,
  ): Promise<void> => {
    if (orderSelected) {
      try {
        setState(States.loading);
        const { data } = await api.get<OrderSite>(`/support/reverse/${orderSelected.id}`);

        setOrder(data);
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }

    setModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const handleOnFilter = async (): Promise<void> => {
    try {
      if (isFormValidFilter()) {
        const filter = formDataFilter[FormInputName.filterSearch];
        const value = formDataFilter[FormInputName.inputSearch];

        const entity = {} as SupportReverseFilter;

        if (filter === 'orderId') {
          entity.orderId = value;
        } else if (filter === 'cpf') {
          entity.cpf = value.replace(/\D/g, '');
        } else if (filter === 'transaction') {
          entity.transaction = value;
        }

        const newPage: Page<SupportReverseFilter, OrderSite> = {
          ...currentPage,
          entity,
        };
        onToggle();
        await getOrders(newPage);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnClearFilter = async (): Promise<void> => {
    resetFormFilter();
    await getOrders({
      ...currentPage,
      entity: {},
    } as Page<SupportReverseFilter, OrderSite>);
    onToggle();
  };

  const handleOnClose = (): void => confirmDelete.hide();

  const handleOnConfirmReverseOrder = async (orderSelected: OrderSite): Promise<void> => {
    try {
      setState(States.loading);
      await api.patch(`/support/reverse/${orderSelected.id}`);
      toast.success('Pedido extornado com sucesso!');
      handleOnClose();
      getOrders(currentPage);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShowReverseOrder = (orderSelected: OrderSite): void => {
    confirmDelete.show({
      title: '',
      children: <ReverseContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero extornar',
          onClick: (): Promise<void> => handleOnConfirmReverseOrder(orderSelected),
        },
      ],
    });
  };

  const handleOnConfirmCancelOrder = async (orderSelected: OrderSite): Promise<void> => {
    try {
      setState(States.loading);
      await api.patch(`/support/reverse/cancel/${orderSelected.id}`);
      toast.success('Pedido cancelado com sucesso!');
      handleOnClose();
      getOrders(currentPage);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShowCancelOrder = (orderSelected: OrderSite): void => {
    confirmDelete.show({
      title: '',
      children: <CancelContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero cancelar',
          onClick: (): Promise<void> => handleOnConfirmCancelOrder(orderSelected),
        },
      ],
    });
  };

  useEffect(() => {
    getOrders(currentPage);
  }, []);
  return (
    <ReverseContainer
      state={state}
      currentPage={currentPage}
      onPaginationChange={handleOnPaginationChange}
      onShouldShowModal={handleOnShouldShowModal}
      title={title}
      visible={visible}
      onToggle={onToggle}
      modal={modal}
      formDataFilter={formDataFilter}
      formErrorsFilter={formErrorsFilter}
      onChangeFormInputFilter={onChangeFormInputFilter}
      onFilter={handleOnFilter}
      onClearFilter={handleOnClearFilter}
      order={order}
      onShowReverseOrder={handleOnShowReverseOrder}
      onShowCancelOrder={handleOnShowCancelOrder}
    />
  );
};
