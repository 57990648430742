import { TableColumn } from 'react-data-table-component';

export type UrlParams = {
  id: string;
};

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

export interface DataRow {
  description: string;
  type: string;
  baseValue: string;
  fee: string;
  value: string;
}

export interface TransferDataRow {
  contractor: string;
  bank: string;
  pixKey: string;
  contractorUser: string;
  transferValue: string;
  transferRequestDate: string;
  transferred: string;
  transferDate: string;
}

export const transferColumns: TableColumn<TransferDataRow>[] = [
  {
    name: 'Produtor',
    selector: row => row.contractor,
    minWidth: '200px',
  },
  {
    name: 'Banco',
    selector: row => row.bank,
    minWidth: '80px',
  },
  {
    name: 'Chave',
    selector: row => row.pixKey,
    minWidth: '150px',
  },
  {
    name: 'Solicitante',
    selector: row => row.contractorUser,
    minWidth: '200px',
  },
  {
    name: 'Valor',
    selector: row => row.transferValue,
    width: '120px',
  },
  {
    name: 'Data solicitação',
    selector: row => row.transferRequestDate,
    width: '170px',
  },
  {
    name: 'Status',
    selector: row => row.transferred,
    width: '120px',
  },
  {
    name: 'Data conclusão',
    selector: row => row.transferDate,
    width: '170px',
  },
];

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Descrição',
    selector: row => row.description,
    minWidth: '35%',
  },
  {
    name: 'Tipo',
    selector: row => row.type,
    minWidth: '15%',
  },
  {
    name: 'Valor base',
    selector: row => row.baseValue,
    minWidth: '20%',
  },
  {
    name: 'Taxa',
    selector: row => row.fee,
    minWidth: '20%',
  },
  {
    name: 'Valor',
    selector: row => row.value,
    width: '120px',
  },
];

// eslint-disable-next-line no-shadow
export enum FormInput {
  contractor = 'contractor',
  pixKey = 'pixKey',
  transferValue = 'transferValue',
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  transfer = 'transfer',
}
