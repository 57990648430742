import React from 'react';
import { Switch } from 'react-router-dom';
import { Error404 } from '@/components';
import { AuthNavigation } from '@/features/auth/navigation';
import { AcquiringFeeNavigation } from '@/features/acquiringFee/navigation';
import { ArtistNavigation } from '@/features/artist/navigation';
import { CancelRequestNavigation } from '@/features/cancelRequest/navigation';
import { ClientNavigation } from '@/features/client/navigation';
import { CreateSaleNavigation } from '@/features/createSale/navigation';
import { DashboardNavigation } from '@/features/dashboard/navigation';
import { DocumentNavigation } from '@/features/document/navigation';
import { FinancialNavigation } from '@/features/financial/navigation';
import { PdvNavigation } from '@/features/pdv/navigation';
import { PaymentGatewayNavigation } from '@/features/paymentGateway/navigation';
import { PosNavigation } from '@/features/pos/navigation';
import { PaymentMethodsNavigation } from '@/features/paymentMethods/navigation';
import { GroupSubgroupProductNavigation } from '@/features/groupSubgroupProduct/navigation';
import { PdvProvider } from '@/features/pdv/hook/usePdv';
import { ProducerReportNavigation } from '@/features/producerReport/navigation';
import { ProductsCombosNavigation } from '@/features/productsAndCombos/navigation';
import { ProductNavigation } from '@/features/product/navigation';
import { ContractorNavigation } from '@/features/contractor/navigation';
import { ComboNavigation } from '@/features/combo/navigation';
import { UserNavigation } from '@/features/usersAndGroups/screens/navigation';
import { ModuleNavigation } from '@/features/module/screens/navigation';
import { PermissionNavigation } from '@/features/permission/screens/navigation';
import { MenuNavigation } from '@/features/menu/screens/navigation';
import { SubMenuNavigation } from '@/features/submenu/screens/navigation';
import { CardFeeNavigation } from '@/features/cardFee/navigation';
import { RegisterEventNavigation } from '@/features/registerEvent/navigation';
import { EmailNavigation } from '@/features/email/navigation';
import { EventsNavigation } from '@/features/events/navigation';
import { FinishedEventsNavigation } from '@/features/finishedEvents/navigation';
import { FinishedEventDetailNavigation } from '@/features/finishedEventDetail/navigation';
import { EventProvider } from '@/features/registerEvent/hook/useEvent';
import { TicketNavigation } from '@/features/ticket/navigation';
import { ReportsNavigation } from '@/features/reports/navigation';
import { EventCloseNavigation } from '@/features/eventClose/navigation';
import { LogNavigation } from '@/features/log/navigation';
import { EventValidatorNavigation } from '@/features/eventValidator/navigation';
import { EventMapNavigation } from '@/features/eventMap/navigation';
import { BraceletNavigation } from '@/features/bracelet/navigation';
import { ReverseNavigation } from '@/features/reverse/navigation';
import { ReprintNavigation } from '@/features/reprint/navigation';
import { SupportNavigation } from '@/features/support/navigation';
import { RedemptionNavigation } from '@/features/redemption/navigation';
import { renderRoutes } from './utils';
import { Route } from './Route';
import { path } from './path';

const Navigation: React.FC = (): JSX.Element => {
  const authRoutes = renderRoutes(AuthNavigation);
  const acquiringFeeRoutes = renderRoutes(AcquiringFeeNavigation);
  const artistRoutes = renderRoutes(ArtistNavigation);
  const braceletRoutes = renderRoutes(BraceletNavigation);
  const cancelRequestRoutes = renderRoutes(CancelRequestNavigation);
  const clientRoutes = renderRoutes(ClientNavigation);
  const createSaleRoutes = renderRoutes(CreateSaleNavigation);
  const dashboardRoutes = renderRoutes(DashboardNavigation);
  const documentRoutes = renderRoutes(DocumentNavigation);
  const emailsRoutes = renderRoutes(EmailNavigation);
  const eventsRoutes = renderRoutes(EventsNavigation);
  const financialRoutes = renderRoutes(FinancialNavigation);
  const finishedEventsRoutes = renderRoutes(FinishedEventsNavigation);
  const finishedEventDetailRoutes = renderRoutes(FinishedEventDetailNavigation);
  const moduleRoutes = renderRoutes(ModuleNavigation);
  const permissionRoutes = renderRoutes(PermissionNavigation);
  const logRoutes = renderRoutes(LogNavigation);
  const menuRoutes = renderRoutes(MenuNavigation);
  const subMenuRoutes = renderRoutes(SubMenuNavigation);
  const cardFeeRoutes = renderRoutes(CardFeeNavigation);
  const userRoutes = renderRoutes(UserNavigation);
  const pdvRoutes = renderRoutes(PdvNavigation);
  const posRoutes = renderRoutes(PosNavigation);
  const ProducerReportRoutes = renderRoutes(ProducerReportNavigation);
  const paymentMethodsRoutes = renderRoutes(PaymentMethodsNavigation);
  const paymentGatewayRoutes = renderRoutes(PaymentGatewayNavigation);
  const productsCombosRoutes = renderRoutes(ProductsCombosNavigation);
  const productRoutes = renderRoutes(ProductNavigation);
  const comboRoutes = renderRoutes(ComboNavigation);
  const groupSubgroupProductRoutes = renderRoutes(GroupSubgroupProductNavigation);
  const companyRoutes = renderRoutes(ContractorNavigation);
  const registerEventRoutes = renderRoutes(RegisterEventNavigation);
  const eventValidatorRoutes = renderRoutes(EventValidatorNavigation);
  const eventMapRoutes = renderRoutes(EventMapNavigation);
  const ticketRoutes = renderRoutes(TicketNavigation);
  const reportsRoutes = renderRoutes(ReportsNavigation);
  const eventCloseRoutes = renderRoutes(EventCloseNavigation);
  const reverseRoutes = renderRoutes(ReverseNavigation);
  const supportRoutes = renderRoutes(SupportNavigation);
  const reprintRoutes = renderRoutes(ReprintNavigation);
  const redemptionRoutes = renderRoutes(RedemptionNavigation);

  return (
    <EventProvider>
      <PdvProvider>
        <Switch>
          {authRoutes}
          {acquiringFeeRoutes}
          {artistRoutes}
          {braceletRoutes}
          {cancelRequestRoutes}
          {cardFeeRoutes}
          {clientRoutes}
          {comboRoutes}
          {companyRoutes}
          {createSaleRoutes}
          {dashboardRoutes}
          {documentRoutes}
          {emailsRoutes}
          {eventsRoutes}
          {financialRoutes}
          {finishedEventsRoutes}
          {finishedEventDetailRoutes}
          {eventCloseRoutes}
          {eventMapRoutes}
          {eventValidatorRoutes}
          {groupSubgroupProductRoutes}
          {moduleRoutes}
          {permissionRoutes}
          {logRoutes}
          {menuRoutes}
          {subMenuRoutes}
          {userRoutes}
          {paymentMethodsRoutes}
          {paymentGatewayRoutes}
          {pdvRoutes}
          {ProducerReportRoutes}
          {posRoutes}
          {productsCombosRoutes}
          {productRoutes}
          {registerEventRoutes}
          {reportsRoutes}
          {reprintRoutes}
          {redemptionRoutes}
          {reverseRoutes}
          {supportRoutes}
          {ticketRoutes}
          <Route path={path.Initial.All} component={Error404} />
        </Switch>
      </PdvProvider>
    </EventProvider>
  );
};

export { Navigation };
