import {
  Button,
  Dialog,
  DropdonwFlags,
  InputText,
  Loading,
  Radio,
  SelectCustom,
} from '@/components';

import { ActionProps } from '@/components/Dialog';
import { States } from '@/helpers/common/states';
import React from 'react';
import { FormData, FormErrors, OnChangeFormInput, UseFormReturn } from '@/hooks';
import { Col, Container, FormGroup, Row } from 'reactstrap';
import { ArrowLeft, Plus, X } from 'react-feather';
import { colors } from '@/styles/colors';
import { Link } from 'react-router-dom';
import { CustomTable } from '@/components/Table';
import Event from '@/model/Event';
import dayjs from 'dayjs';
import { toCurrency } from '@/helpers/masks/toCurrency';
import EventSiteGet from '@/model/EventSiteGet';
import TicketSite from '@/model/TicketSite';
import CheckDiscount from '@/model/CheckDiscount';
import CreateOrderAdmResponse from '@/model/CreateOrderAdmResponse';
import TicketPdf from '@/model/TicketPdf';
import OrderSite from '@/model/OrderSite';
import Page from '@/model/Page';
import Pagination from '@/components/Utils/Pagination';
import OrderPayment from '@/model/OrderPayment';
import PaymentStatus from '@/model/PaymentStatus';
import { updateMask } from '@/helpers/masks/cep';
import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import EventMap from '@/model/EventMap';
import ChartObject from '@/model/ChartObject';
import ChartSelectedTicketType from '@/model/ChartSelectedTicketType';
import { SelectedObject } from '@/model/SelectedObject';
import {
  DataColumn,
  DataRow,
  FormInputName,
  OnShouldShowModalProps,
  ShouldShowModal,
  UserDataFormInputName,
  columns,
} from '../../types';
import { QrCodeContent } from '../../components/QrCodeContent';
import { TicketContent } from '../../components/TicketContent';
import { UrlContent } from '../../components/UrlContent';
import { DetailContent } from '../../components/DetailContent';
import { MapContent } from '../../components/MapContent';

interface StateProps {
  state: States;
  title: React.ReactNode | string;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  formData: FormData;
  formErrors: FormErrors;
  events: Event[];
  event?: EventSiteGet;
  eventMap?: EventMap;
  eventKey?: string;
  selectedObjects: SelectedObject[];
  amount: number;
  totalValue: number;
  fee: number;
  discount?: CheckDiscount;
  formControllerUserData: UseFormReturn;
  totalWithFee: number;
  disableName: boolean;
  disableEmail: boolean;
  disablePhone: boolean;
  disableBirthDate: boolean;
  disableMotherName: boolean;
  response?: CreateOrderAdmResponse;
  currentPage: Page<OrderSite, OrderSite>;
  order?: OrderSite;
}

interface DispatchProps {
  onToggle: () => void;
  onChangeFormInput: OnChangeFormInput;
  onSelectEvent: (eventId: string) => void;
  onObjectSelected: (object: ChartObject, selectedTicketType: ChartSelectedTicketType) => void;
  onObjectDeselected: (object: ChartObject, selectedTicketType: ChartSelectedTicketType) => void;
  onAddTicket: (ticketId: string, isHalfPrice: boolean) => void;
  onRemoveTicket: (ticketId: string, isHalfPrice: boolean) => void;
  onAddDiscount: () => void;
  onRemoveDiscount: () => void;
  onShouldShowModal: (props: OnShouldShowModalProps) => void;
  onCheckCpf: (cpf: string) => void;
  save: () => void;
  onCopyUrl: (url: string) => void;
  onPaginationChange: (page: number) => void;
}

type Props = StateProps & DispatchProps;

export const CreateSaleContainer: React.FC<Props> = ({
  state,
  title,
  visible,
  shouldShowModal,
  onToggle,
  formData,
  formErrors,
  onChangeFormInput,
  events,
  onSelectEvent,
  event,
  eventMap,
  eventKey,
  selectedObjects,
  onObjectSelected,
  onObjectDeselected,
  onAddTicket,
  onRemoveTicket,
  onAddDiscount,
  onRemoveDiscount,
  discount,
  formControllerUserData,
  amount,
  totalValue,
  fee,
  totalWithFee,
  onShouldShowModal,
  disableName,
  disableEmail,
  disablePhone,
  disableBirthDate,
  disableMotherName,
  onCheckCpf,
  save,
  response,
  onCopyUrl,
  currentPage,
  onPaginationChange,
  order,
}): JSX.Element => {
  const renderActionDialogToClose: ActionProps = {
    title: 'Fechar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };

  const getTickets = (sectionId: string): TicketSite[] => {
    const tickets: TicketSite[] = [];
    if (event && event.sections && event.sections.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let s = 0; s < event.sections.length; s++) {
        if (event.sections[s].section.id === sectionId) {
          if (event.sections[s].tickets && event.sections[s].tickets.length > 0) {
            event.sections[s].tickets.forEach(data => {
              tickets.push(data);
            });
          }
          break;
        }
      }
    }
    return tickets;
  };

  const getEventKey = (ticketId: string): string => {
    let value = '';
    if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let s = 0; s < eventMap.sections.length; s++) {
        if (eventMap.sections[s].tickets && eventMap.sections[s].tickets.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let t = 0; t < eventMap.sections[s].tickets.length; t++) {
            if (eventMap.sections[s].tickets[t].ticket.id === ticketId) {
              value = eventMap.sections[s].tickets[t].eventKey;
              break;
            }
          }
        }
        if (value.length > 0) {
          break;
        }
      }
    }
    return value;
  };

  const mountValue = (unitValue: number, feeValue: number): string => {
    let value = toCurrency(unitValue);
    if (feeValue && feeValue > 0) {
      const calc = Number(unitValue) * Number(feeValue) - Number(unitValue);
      value += ` + Taxa ${toCurrency(calc)}`;
    }
    return value;
  };

  const getDiscountText = (): string => {
    let value = '';
    if (discount) {
      if (discount.discountCoupon) {
        value = discount.discountCoupon.name;
      } else if (discount.voucher) {
        value = discount.voucher.code;
      }
    }
    return value;
  };

  const getDiscountValue = (): number => {
    let value = 0;
    if (discount) {
      if (discount.discountCoupon) {
        value = Number(discount.discountCoupon.discount);
      } else if (discount.voucher) {
        value = Number(discount.voucher.value);
      }
    }
    return value;
  };

  const getValidTickets = (): TicketSite[] => {
    const tickets: TicketSite[] = [];
    if (event && event.sections && event.sections.length > 0) {
      event.sections.forEach(eventSection => {
        if (eventSection.tickets && eventSection.tickets.length > 0) {
          eventSection.tickets.forEach(data => {
            if (data.amount && data.amount > 0) {
              tickets.push(data);
            }
          });
        }
      });
    }
    return tickets;
  };

  const mountDataColumnPaymentType = (payments: OrderPayment[]): DataColumn[] => {
    const dataColumnProfiles: DataColumn[] = [];
    if (payments && payments.length > 0) {
      payments.forEach((data, index) => {
        dataColumnProfiles.push({
          id: String(index),
          name: data.transaction,
        });
      });
    }
    return dataColumnProfiles;
  };

  const mountTransaction = (
    payments: OrderPayment[],
    transferOrder?: string,
  ): string | JSX.Element => {
    if (transferOrder) {
      return transferOrder;
    }
    if (payments) {
      if (payments.length === 1) {
        return payments[0].transaction;
      }
      return (
        <DropdonwFlags pointerClass={true} dataColumn={mountDataColumnPaymentType(payments)} />
      );
    }
    return '';
  };

  const getStatus = (paymentStatus: PaymentStatus, transferOrder?: string): string => {
    let value = '';
    if (transferOrder) {
      value = 'Transferido';
    } else if (paymentStatus === PaymentStatus.APPROVED) {
      value = 'Aprovado';
    } else if (paymentStatus === PaymentStatus.DISAPPROVED) {
      value = 'Reprovado';
    } else if (paymentStatus === PaymentStatus.AWAITING_PAYMENT) {
      value = 'Aguardando Pagamento';
    } else if (paymentStatus === PaymentStatus.CANCELED) {
      value = 'Cancelado';
    } else if (paymentStatus === PaymentStatus.CANCELLATION_ANALYSIS) {
      value = 'Aguardando analise cancelamento';
    }

    return value;
  };

  const mountDataTable = (): DataRow[] => {
    const list: DataRow[] = [];
    if (currentPage && currentPage.list && currentPage.list.length > 0) {
      currentPage.list.forEach(data => {
        const dataRow: DataRow = {
          client: `${data.client.name} - ${updateMask(data.client.cpf)}`,
          orderId: data.id,
          orderDate: dayjs(data.orderDate).format('DD/MM/YYYY [às] HH:mm'),
          orderValue: toCurrency(data.totalValue),
          transaction: mountTransaction(data.payments, data.transferOrder),
          paymentStatus: getStatus(data.paymentStatus, data.transferOrder),
          actions: (
            <React.Fragment>
              <Detail
                className={'mr-4 svg-icon action-icon'}
                onClick={() =>
                  onShouldShowModal({
                    value: ShouldShowModal.detail,
                    newTitleModal: 'Pedido',
                    orderSelected: data,
                  })
                }
              />
            </React.Fragment>
          ),
        };
        list.push(dataRow);
      });
    }
    return list;
  };
  return (
    <React.Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.map]: renderActionDialogToClose,
            [ShouldShowModal.qrCode]: renderActionDialogToClose,
            [ShouldShowModal.ticket]: renderActionDialogToClose,
            [ShouldShowModal.url]: renderActionDialogToClose,
            [ShouldShowModal.detail]: renderActionDialogToClose,
          }[shouldShowModal],
          {
            [ShouldShowModal.map]: {},
            [ShouldShowModal.qrCode]: {},
            [ShouldShowModal.ticket]: {},
            [ShouldShowModal.url]: {},
            [ShouldShowModal.detail]: {},
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.map]: (
              <MapContent
                eventMap={eventMap}
                eventKey={eventKey}
                selectedObjects={selectedObjects}
                onObjectSelected={onObjectSelected}
                onObjectDeselected={onObjectDeselected}
              />
            ),
            [ShouldShowModal.qrCode]: <QrCodeContent qrCode={response?.qrCode as string} />,
            [ShouldShowModal.ticket]: <TicketContent tickets={response?.tickets as TicketPdf[]} />,
            [ShouldShowModal.url]: (
              <UrlContent url={response?.url as string} onCopyUrl={onCopyUrl} />
            ),
            [ShouldShowModal.detail]: <DetailContent order={order} />,
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="pageTitle d-flex mb-5">
          <Link to={`${process.env.PUBLIC_URL}/dashboard/event`}>
            <ArrowLeft color={colors.black} className="arrow-left" />
          </Link>
          <h5 className="ml-3 mb-0 mt-2 pageTitle">Criar pedido</h5>
        </div>
        <h5 className="mb-2 border-bottom-title mb-5">Registrar</h5>
        <Row>
          <Col md={8}>
            <FormGroup className="mb-2">
              <SelectCustom
                name="eventId"
                label="Evento"
                placeholder="Digite ou selecione o evento"
                onChange={e => {
                  onChangeFormInput(FormInputName.eventId)(e?.value as string);
                  onSelectEvent(e?.value as string);
                }}
                error={formErrors.eventId && formErrors.eventId[0]}
                value={formData[FormInputName.eventId]}
                options={events.map(item => ({
                  value: item.id,
                  label: `${item.name} - ${dayjs(item.startDate).format('DD/MM/YYYY')}`,
                }))}
              />
            </FormGroup>
          </Col>
        </Row>
        {event && event.sections && event.sections.length > 0 && (
          <>
            <Row>
              <Col md={8}>
                <FormGroup className="mb-2">
                  <SelectCustom
                    name="sectionId"
                    label="Setor"
                    placeholder="Digite ou selecione o setor"
                    onChange={e => {
                      onChangeFormInput(FormInputName.sectionId)(e?.value as string);
                    }}
                    error={formErrors.sectionId && formErrors.sectionId[0]}
                    value={formData[FormInputName.sectionId]}
                    options={event.sections.map(item => ({
                      value: item.section.id,
                      label: item.section.name,
                    }))}
                  />
                </FormGroup>
              </Col>
            </Row>
            {formData[FormInputName.sectionId] &&
              formData[FormInputName.sectionId].trim().length > 0 && (
                <Row>
                  <Col md={8}>
                    <CustomTable
                      columns={[
                        {
                          name: 'Ingresso',
                          selector: row => row.name,
                        },
                        {
                          name: 'Valor',
                          selector: row => row.unitValue,
                        },
                        {
                          name: '',
                          selector: row => row.actions,
                          right: true,
                        },
                        {
                          name: 'Total',
                          selector: row => row.total,

                          right: true,
                        },
                      ]}
                      data={getTickets(formData[FormInputName.sectionId]).map(data => {
                        const value = {
                          name: data.name,
                          unitValue: mountValue(data.unitValue, data.fee),
                          actions: data.hasMap ? (
                            <button
                              style={{
                                display: 'inline-block',
                                fontWeight: 400,
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                                verticalAlign: 'middle',
                                userSelect: 'none',
                                border: '1px solid transparent',
                                borderRadius: '0.25rem',
                                transition:
                                  'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                                color: '#fff',
                                backgroundColor: '#007bff',
                                borderColor: '#007bff',
                              }}
                              onClick={(): void =>
                                onShouldShowModal({
                                  value: ShouldShowModal.map,
                                  newTitleModal: 'Mapa',
                                  orderSelected: undefined,
                                  eventKey: getEventKey(data.id),
                                })
                              }
                            >
                              Selecionar
                            </button>
                          ) : (
                            <div style={{ overflow: 'initial', minHeight: '30px' }}>
                              <ul className="pagination-container">
                                <li
                                  className={`${
                                    data.amount === undefined || data.amount === 0 ? 'disabled' : ''
                                  }`}
                                >
                                  <span
                                    aria-label="Previous"
                                    className={`previous-pagination ${
                                      data.amount === undefined || data.amount === 0
                                        ? 'disabled'
                                        : ''
                                    }`}
                                    onClick={(): void => onRemoveTicket(data.id, data.isHalfPrice)}
                                  >
                                    -
                                  </span>
                                </li>
                                <li className={'active'}>
                                  <span>{data.amount || 0}</span>
                                </li>
                                <li
                                  className={`${data.count > (data.amount || 0) ? '' : 'disabled'}`}
                                >
                                  <span
                                    aria-label="Next"
                                    className={`next-pagination ${
                                      data.count > (data.amount || 0) ? '' : 'disabled'
                                    }`}
                                    onClick={(): void => onAddTicket(data.id, data.isHalfPrice)}
                                  >
                                    +
                                  </span>
                                </li>
                              </ul>
                            </div>
                          ),
                          total: toCurrency(data.totalValue || 0),
                        };
                        return value;
                      })}
                      theme="secondary"
                      progressPending={false}
                      numberRowsPerPage={1000}
                    />
                  </Col>
                </Row>
              )}
          </>
        )}
        {totalValue > 0 && (
          <>
            <Row style={{ marginTop: '40px' }}>
              <Col md={7}>
                <FormGroup className="mb-2">
                  {!discount ? (
                    <InputText
                      name="code"
                      label="Cupom de desconto (apenas um cupom por compra)"
                      placeholder="ABCD1234"
                      value={formData[FormInputName.code]}
                      onChange={e => onChangeFormInput(FormInputName.code)(e.target.value)}
                      error={formErrors.code && formErrors.code[0]}
                    />
                  ) : (
                    <InputText
                      name="discount"
                      label="Cupom de desconto (apenas um cupom por compra)"
                      value={getDiscountText()}
                      disabled
                      onChange={window.console.log}
                    />
                  )}
                </FormGroup>
              </Col>
              <Col md={1} className="pr-0">
                <div style={{ paddingTop: '50px' }}>
                  {!discount ? (
                    <Plus className="svg-icon action-icon" onClick={onAddDiscount} />
                  ) : (
                    <X className="svg-icon action-icon" onClick={onRemoveDiscount} />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <p>Carrinho</p>
                <hr />
                <CustomTable
                  columns={[
                    {
                      name: 'Ingresso',
                      selector: row => row.name,
                      width: '35%',
                    },
                    {
                      name: 'Qtd',
                      selector: row => row.amount,
                      maxWidth: '160px',
                      width: '20%',
                      center: true,
                    },
                    {
                      name: 'Valor',
                      selector: row => row.unitValue,
                      width: '30%',
                      center: true,
                    },
                    {
                      name: 'Total',
                      selector: row => row.total,
                      width: '15%',
                      right: true,
                    },
                  ]}
                  data={getValidTickets().map(data => {
                    const value = {
                      name: data.name,
                      amount: data.amount,
                      unitValue: mountValue(data.unitValue, data.fee),
                      total: toCurrency(data.totalValue || 0),
                    };
                    return value;
                  })}
                  theme="secondary"
                  progressPending={false}
                  numberRowsPerPage={1000}
                />
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col md={8}>
            <hr />
            <p>{`Total de itens: ${amount}`}</p>
            <p>{`Sub total: ${toCurrency(totalValue)}`}</p>
            <p>{`Taxa: ${toCurrency(fee)}`}</p>
            {discount && <p>{`Desconto: ${toCurrency(getDiscountValue())}`}</p>}
            <hr />
            <p>{`Valor total: ${toCurrency(totalWithFee)}`}</p>
          </Col>
        </Row>
        {totalValue > 0 && (
          <React.Fragment>
            <Row>
              <Col md={8}>
                <hr />
                <FormGroup className="mb-2">
                  <span className="filter-name">Formas de pagamento</span>
                  <Radio
                    options={[
                      { label: 'Site', value: 'site' },
                      { label: 'POS', value: 'pos' },
                      { label: 'Dinheiro', value: 'money' },
                    ]}
                    name="paymentForm"
                    style={{ fontSize: '21px' }}
                    value={formData[FormInputName.paymentForm]}
                    onChange={e => onChangeFormInput(FormInputName.paymentForm)(e.target.value)}
                    error={formErrors.paymentForm && formErrors.paymentForm[0]}
                  />
                </FormGroup>
              </Col>
            </Row>
            {formData[FormInputName.paymentForm] === 'money' && (
              <React.Fragment>
                <Row>
                  <Col md={8}>
                    <InputText
                      name="document"
                      label="CPF"
                      placeholder="123.456.789-00"
                      value={formControllerUserData.formData[UserDataFormInputName.document]}
                      onChange={e =>
                        formControllerUserData.onChangeFormInput(UserDataFormInputName.document)(
                          e.target.value,
                        )
                      }
                      onBlur={e => onCheckCpf(e.target.value)}
                      error={
                        formControllerUserData.formErrors.document &&
                        formControllerUserData.formErrors.document[0]
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <InputText
                      name="name"
                      label="Nome"
                      placeholder="Maria da Silva"
                      value={formControllerUserData.formData[UserDataFormInputName.name]}
                      disabled={disableName}
                      onChange={e =>
                        formControllerUserData.onChangeFormInput(UserDataFormInputName.name)(
                          e.target.value,
                        )
                      }
                      error={
                        formControllerUserData.formErrors.name &&
                        formControllerUserData.formErrors.name[0]
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <InputText
                      name="email"
                      label="E-mail"
                      placeholder="meuemail@email.com"
                      value={formControllerUserData.formData[UserDataFormInputName.email]}
                      disabled={disableEmail}
                      onChange={e =>
                        formControllerUserData.onChangeFormInput(UserDataFormInputName.email)(
                          e.target.value,
                        )
                      }
                      error={
                        formControllerUserData.formErrors.email &&
                        formControllerUserData.formErrors.email[0]
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <InputText
                      name="phone"
                      label="Telefone"
                      placeholder="(00) 0 0000-0000"
                      value={formControllerUserData.formData[UserDataFormInputName.phone]}
                      disabled={disablePhone}
                      onChange={e =>
                        formControllerUserData.onChangeFormInput(UserDataFormInputName.phone)(
                          e.target.value,
                        )
                      }
                      error={
                        formControllerUserData.formErrors.phone &&
                        formControllerUserData.formErrors.phone[0]
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <InputText
                      name="birthDate"
                      label="Data de Nascimento"
                      placeholder="00/00/0000"
                      value={formControllerUserData.formData[UserDataFormInputName.birthDate]}
                      disabled={disableBirthDate}
                      onChange={e =>
                        formControllerUserData.onChangeFormInput(UserDataFormInputName.birthDate)(
                          e.target.value,
                        )
                      }
                      error={
                        formControllerUserData.formErrors.birthDate &&
                        formControllerUserData.formErrors.birthDate[0]
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <InputText
                      name="motherName"
                      label="Nome da mãe"
                      placeholder="Maria da Silva"
                      value={formControllerUserData.formData[UserDataFormInputName.motherName]}
                      disabled={disableMotherName}
                      onChange={e =>
                        formControllerUserData.onChangeFormInput(UserDataFormInputName.motherName)(
                          e.target.value,
                        )
                      }
                      error={
                        formControllerUserData.formErrors.motherName &&
                        formControllerUserData.formErrors.motherName[0]
                      }
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <Row>
          <Col md={8}>
            <div className="footer-register-event">
              <Button
                title="Registrar Venda"
                onClick={() => save()}
                style={{ marginRight: 0 }}
                disabled={totalValue === undefined || totalValue === 0}
              />
            </div>
          </Col>
        </Row>
        <h5 className="mb-2 border-bottom-title mb-5">Pedidos</h5>
        <Row>
          <Col md={12}>
            {currentPage.list && currentPage.list.length > 0 ? (
              <>
                <CustomTable
                  columns={columns}
                  data={mountDataTable()}
                  numberRowsPerPage={currentPage.total || 0}
                  progressPending={state === States.loading}
                />
                <Pagination
                  currentPage={currentPage.page}
                  totalCount={currentPage.total}
                  pageSize={currentPage.pageSize}
                  onPageChange={(page: number) => onPaginationChange(page)}
                  total={currentPage.total}
                />
              </>
            ) : (
              <>
                <div style={{ padding: '10px 0 20px 0', color: '#A5A5A5' }}>
                  Você ainda não inseriu nenhum pedido.
                </div>
                <div style={{ color: '#A5A5A5', paddingBottom: '30px' }}>
                  Aqui será exibida uma lista de pedidos.
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
