import FilterVector from '@/assets/images/svg/FilterVector';
import { Dialog, Loading } from '@/components';
import { CustomTable } from '@/components/Table';
import Pagination from '@/components/Utils/Pagination';
import React, { Fragment, ReactNode } from 'react';
import { Container } from 'reactstrap';
import Page from '@/model/Page';
import { EventMoneyTransfer } from '@/model/EventMoneyTransfer';
import { ActionProps } from '@/components/Dialog';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { toCurrency } from '@/helpers/masks/toCurrency';
import dayjs from 'dayjs';
import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import { ReactComponent as Transfer } from '@/assets/images/svg/transfer.svg';
import { columns, ShouldShowModal, States } from '../../types';
import { FilterContent } from '../../components/FilterContent';
import { DetailContent } from '../../components/DetailContent';

interface StateProps {
  state: States;
  title: string | ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  formData: FormData;
  formErrors: FormErrors;
  page: Page<EventMoneyTransfer, EventMoneyTransfer>;
}

interface DispatchProps {
  onToggle: () => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
    id,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    id?: string;
  }) => void;
  onChangeFormInput: OnChangeFormInput;
  onClearFilter: () => void;
  onFilter: () => void;
  onPaginationChange: (newPageNumber: number) => void;
  eventMoneyTransfer?: EventMoneyTransfer;
  onTransfer: (id: string) => void;
}

type Props = StateProps & DispatchProps;
export const RedemptionContainer: React.FC<Props> = ({
  state,
  title,
  visible,
  onToggle,
  shouldShowModal,
  onShouldShowModal,
  formData,
  formErrors,
  onChangeFormInput,
  onClearFilter,
  onFilter,
  page,
  onPaginationChange,
  eventMoneyTransfer,
  onTransfer,
}): JSX.Element => {
  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => onClearFilter(),
    theme: 'noneBorder',
  };
  const renderActionDialogToCancel: ActionProps = {
    title: 'Fechar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };
  const dataTable =
    page && page.list && page.list.length > 0
      ? page.list.map(item => ({
          event: item.event.name,
          contractor: item.contractor.name,
          bank: item.pixKey.bank.fullName,
          pixKey: item.pixKey.key,
          contractorUser: item.contractorUser.name,
          transferValue: toCurrency(item.transferValue),
          transferRequestDate: dayjs(item.transferRequestDate).format('DD/MM/YYYY HH:mm:ss'),
          transferred: item.transferred ? 'Transferindo' : 'Aguardando',
          transferDate: item.transferDate
            ? dayjs(item.transferDate).format('DD/MM/YYYY HH:mm:ss')
            : '---',
          action: (
            <React.Fragment>
              <Detail
                className={'mr-4 svg-icon action-icon'}
                onClick={() =>
                  onShouldShowModal({
                    value: ShouldShowModal.detail,
                    newTitleModal: 'Pedido',
                    id: item.id,
                  })
                }
              />
              {!item.transferDate && (
                <Transfer
                  title="Resgatar"
                  className={'mr-4 svg-icon-error action-icon'}
                  onClick={() => onTransfer(item.id)}
                />
              )}
            </React.Fragment>
          ),
        }))
      : [];
  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        footerBorder={'top'}
        position={shouldShowModal === ShouldShowModal.filter ? 'right' : 'center'}
        isContentWithCard={shouldShowModal !== ShouldShowModal.filter}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToCancelFilter,
            [ShouldShowModal.detail]: renderActionDialogToCancel,
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: {
              title: 'Aplicar',
              onClick: onFilter,
            },
            [ShouldShowModal.detail]: {},
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formData}
                formErrors={formErrors}
                onChangeFormInput={onChangeFormInput}
              />
            ),
            [ShouldShowModal.detail]: <DetailContent data={eventMoneyTransfer} />,
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <h5 className="pageTitle">Reimprimir Ingresso</h5>
          <div className="button-filter-container">
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onShouldShowModal({ value: ShouldShowModal.filter, newTitleModal: 'Filtrar' });
                }}
              >
                <FilterVector />
              </div>
            </div>
          </div>
        </div>
        <CustomTable
          columns={columns}
          data={dataTable}
          numberRowsPerPage={page.total || 0}
          progressPending={state === States.loading}
        />
        <Pagination
          currentPage={page.page}
          totalCount={page.total}
          pageSize={page.pageSize}
          onPageChange={(data: number) => onPaginationChange(data)}
          total={page.total}
        />
      </Container>
    </Fragment>
  );
};
