import React, { ReactNode } from 'react';
import { Container } from 'reactstrap';
import Support from '@/model/Support';
import { States } from '@/helpers/common/states';
import { Loading } from '@/components';
import FilterVector from '@/assets/images/svg/FilterVector';
import { CustomTable } from '@/components/Table';
import SupportStatus from '@/model/SupportStatus';
import { updateMask } from '@/helpers/masks/cpf';
import dayjs from 'dayjs';
import Page from '@/model/Page';
import { columns, DataRow, ShouldShowModal } from '@/features/support/types';
import { ActionProps, Dialog } from '@/components/Dialog';
import { FilterContent } from '@/features/support/components/FilterContent';
import Pagination from '@/components/Utils/Pagination';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import SupportType from '@/model/SupportType';

interface StateProps {
  state: States;
  title: string | ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  formData: FormData;
  formErrors: FormErrors;
  currentPage: Page<Support, Support>;
}
interface DispatchProps {
  onToggle: () => void;
  onGoToChat: (id: string) => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
  }) => void;
  onChangeFormInput: OnChangeFormInput;
  onFilter: () => void;
  onClearFilter: () => void;
  onPagination: (page: number) => void;
}

type Props = StateProps & DispatchProps;

export const SupportUI: React.FC<Props> = ({
  state,
  title,
  visible,
  shouldShowModal,
  onToggle,
  onShouldShowModal,
  formData,
  formErrors,
  onChangeFormInput,
  onFilter,
  onClearFilter,
  currentPage,
  onGoToChat,
  onPagination,
}): JSX.Element => {
  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => onClearFilter(),
    theme: 'noneBorder',
  };

  const getType = (supportType: SupportType): string => {
    let value = '';
    if (supportType === SupportType.CANCELLATION) {
      value = 'Cancelamento';
    } else if (supportType === SupportType.PAYMENTS) {
      value = 'Pagamento';
    } else if (supportType === SupportType.TICKET) {
      value = 'Ingresso';
    } else if (supportType === SupportType.ACCOUNT) {
      value = 'Conta';
    } else if (supportType === SupportType.OTHERS) {
      value = 'Outros';
    }
    return value;
  };

  const getStatus = (supportStatus: SupportStatus): string => {
    let value = '';

    if (supportStatus === SupportStatus.NEW) {
      value = 'Novo Chamado';
    } else if (supportStatus === SupportStatus.WAITING) {
      value = 'Aguardando';
    } else if (supportStatus === SupportStatus.IN_PROGRESS) {
      value = 'Em Andamento';
    } else if (supportStatus === SupportStatus.WAITING_FOR_CLIENT) {
      value = 'Aguardando Cliente';
    } else if (supportStatus === SupportStatus.RESOLVED) {
      value = 'Resolvido';
    } else if (supportStatus === SupportStatus.CLOSED) {
      value = 'Fechado';
    } else if (supportStatus === SupportStatus.REOPENED) {
      value = 'Reaberto';
    } else if (supportStatus === SupportStatus.CANCELED) {
      value = 'Cancelado';
    }
    return value;
  };

  const mountDataTable = (): DataRow[] => {
    const list: DataRow[] = [];
    if (currentPage && currentPage.list && currentPage.list.length > 0) {
      currentPage.list.forEach(data => {
        const dataRow: DataRow = {
          id: data.id,
          client: data.name,
          document: updateMask(data.cpf),
          protocol: data.protocol,
          chatDate: dayjs(data.startDate).format('DD/MM/YYYY [às] HH:mm'),
          type: getType(data.supportType),
          status: getStatus(data.supportStatus),
        };
        list.push(dataRow);
      });
    }
    return list;
  };

  return (
    <React.Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        footerBorder={'top'}
        position={shouldShowModal === ShouldShowModal.filter ? 'right' : 'center'}
        isContentWithCard={shouldShowModal !== ShouldShowModal.filter}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToCancelFilter,
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: {
              title: 'Aplicar',
              onClick: onFilter,
            },
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formData}
                formErrors={formErrors}
                onChangeFormInput={onChangeFormInput}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <h5 className="pageTitle">Atendimento</h5>
          <div className="button-filter-container">
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onShouldShowModal({ value: ShouldShowModal.filter, newTitleModal: '' });
                }}
              >
                <FilterVector />
              </div>
            </div>
          </div>
        </div>
        <CustomTable
          columns={columns}
          data={mountDataTable()}
          numberRowsPerPage={currentPage.list ? currentPage.list.length : 0}
          progressPending={state === States.loading}
          getRowId={(row: DataRow) => onGoToChat(row.id)}
        />
        <Pagination
          currentPage={currentPage.page}
          totalCount={currentPage.total}
          pageSize={currentPage.pageSize}
          onPageChange={(page: number) => onPagination(page)}
          total={currentPage.total}
        />
      </Container>
    </React.Fragment>
  );
};
