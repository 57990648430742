export type UrlParams = {
  id: string;
};

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

// eslint-disable-next-line no-shadow
export enum FormInput {
  pdv = 'pdv',
  credit = 'credit',
  installment = 'installment',
  debit = 'debit',
  pix = 'pix',
  bankslip = 'bankslip',
  food = 'food',
  technical = 'technical',
  trip = 'trip',
  rentPos = 'rentPos',
}
