import React from 'react';
import { Container } from 'reactstrap';
import dayjs from 'dayjs';
import { Dialog, DropdonwFlags, Loading } from '@/components';
import { CustomTable } from '@/components/Table';
import { States } from '@/helpers/common/states';
import OrderSite from '@/model/OrderSite';
import { updateMask } from '@/helpers/masks/cpf';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import { ReactComponent as Transfer } from '@/assets/images/svg/transfer.svg';
import { ReactComponent as X } from '@/assets/images/svg/x.svg';
import OrderPayment from '@/model/OrderPayment';
import { ActionProps } from '@/components/Dialog';
import { ReverseDetailContent } from '@/features/reverse/components/ReverseDetailContent';
import FilterVector from '@/assets/images/svg/FilterVector';
import { FilterContent } from '@/features/reverse/components/FilterContent';
import { DataColumn, ShouldShowModal } from '@/features/reverse/types';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import Page from '@/model/Page';
import SupportReverseFilter from '@/model/SupportReverseFilter';
import Pagination from '@/components/Utils/Pagination';
import PaymentStatus from '@/model/PaymentStatus';
import { DataRow, columns } from './table';

interface StateProps {
  state: States;
  currentPage: Page<SupportReverseFilter, OrderSite>;
  title: string | React.ReactNode;
  visible: boolean;
  modal: ShouldShowModal;
  formDataFilter: FormData;
  formErrorsFilter: FormErrors;
  order?: OrderSite;
}

interface DispatchProps {
  onPaginationChange: (page: number) => void;
  onShouldShowModal: (
    value: ShouldShowModal,
    newTitleModal: string | React.ReactNode,
    orderSelected?: OrderSite,
  ) => void;
  onToggle: () => void;
  onChangeFormInputFilter: OnChangeFormInput;
  onFilter: () => void;
  onClearFilter: () => void;
  onShowReverseOrder: (orderSelected: OrderSite) => void;
  onShowCancelOrder: (orderSelected: OrderSite) => void;
}

type Props = StateProps & DispatchProps;

export const ReverseContainer: React.FC<Props> = ({
  state,
  currentPage,
  onPaginationChange,
  onShouldShowModal,
  title,
  visible,
  onToggle,
  modal,
  formDataFilter,
  formErrorsFilter,
  onChangeFormInputFilter,
  onFilter,
  onClearFilter,
  order,
  onShowReverseOrder,
  onShowCancelOrder,
}): JSX.Element => {
  const mountDataColumnPaymentType = (payments: OrderPayment[]): DataColumn[] => {
    const dataColumnProfiles: DataColumn[] = [];
    if (payments && payments.length > 0) {
      payments.forEach((data, index) => {
        dataColumnProfiles.push({
          id: String(index),
          name: data.transaction,
        });
      });
    }
    return dataColumnProfiles;
  };

  const mountTransaction = (
    payments: OrderPayment[],
    transferOrder?: string,
  ): string | JSX.Element => {
    if (transferOrder) {
      return transferOrder;
    }
    if (payments) {
      if (payments.length === 1) {
        return payments[0].transaction;
      }
      return (
        <DropdonwFlags pointerClass={true} dataColumn={mountDataColumnPaymentType(payments)} />
      );
    }
    return '';
  };

  const getStatus = (paymentStatus: PaymentStatus, transferOrder?: string): string => {
    let value = '';
    if (transferOrder) {
      value = 'Transferido';
    } else if (paymentStatus === PaymentStatus.APPROVED) {
      value = 'Aprovado';
    } else if (paymentStatus === PaymentStatus.DISAPPROVED) {
      value = 'Reprovado';
    } else if (paymentStatus === PaymentStatus.AWAITING_PAYMENT) {
      value = 'Aguardando Pagamento';
    } else if (paymentStatus === PaymentStatus.CANCELED) {
      value = 'Cancelado';
    } else if (paymentStatus === PaymentStatus.CANCELLATION_ANALYSIS) {
      value = 'Aguardando analise cancelamento';
    }

    return value;
  };

  const mountDataTable = (): DataRow[] => {
    const list: DataRow[] = [];
    if (currentPage && currentPage.list && currentPage.list.length > 0) {
      currentPage.list.forEach(data => {
        const dataRow: DataRow = {
          client: `${data.client.name} - ${updateMask(data.client.cpf)}`,
          orderId: data.id,
          orderDate: dayjs(data.orderDate).format('DD/MM/YYYY [às] HH:mm'),
          orderValue: toCurrency(data.totalValue),
          transaction: mountTransaction(data.payments, data.transferOrder),
          paymentStatus: getStatus(data.paymentStatus, data.transferOrder),
          actions: (
            <React.Fragment>
              {!data.cancelDate && (
                <Transfer
                  title="Extornar pedido"
                  className={'mr-4 svg-icon-error action-icon'}
                  onClick={() => onShowReverseOrder(data)}
                />
              )}
              <Detail
                className={'mr-4 svg-icon action-icon'}
                onClick={() => onShouldShowModal(ShouldShowModal.detail, 'Pedido', data)}
              />
              {!data.transferOrder && (
                <X
                  title="Cancelar pedido"
                  className={'mr-4 svg-icon-error action-icon'}
                  onClick={() => onShowCancelOrder(data)}
                />
              )}
            </React.Fragment>
          ),
        };
        list.push(dataRow);
      });
    }
    return list;
  };

  const renderActionDialogToFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => onClearFilter(),
    theme: 'noneBorder',
  };

  const renderActionDialogToCancel: ActionProps = {
    title: 'Fechar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };

  return (
    <React.Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={'center'}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToFilter,
            [ShouldShowModal.detail]: renderActionDialogToCancel,
          }[modal],
          {
            [ShouldShowModal.filter]: {
              title: 'Filtrar',
              onClick: () => onFilter(),
            },
            [ShouldShowModal.detail]: {
              title: 'OK',
            },
          }[modal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formDataFilter}
                formErrors={formErrorsFilter}
                onChangeFormInput={onChangeFormInputFilter}
              />
            ),
            [ShouldShowModal.detail]: <ReverseDetailContent order={order} />,
          }[modal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <h5 className="pageTitle">Cancelar Pedido</h5>
          <div className="button-filter-container">
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onShouldShowModal(ShouldShowModal.filter, '');
                }}
              >
                <FilterVector />
              </div>
            </div>
          </div>
        </div>
        <CustomTable
          columns={columns}
          data={mountDataTable()}
          numberRowsPerPage={currentPage.total || 0}
          progressPending={state === States.loading}
        />
        <Pagination
          currentPage={currentPage.page}
          totalCount={currentPage.total}
          pageSize={currentPage.pageSize}
          onPageChange={(page: number) => onPaginationChange(page)}
          total={currentPage.total}
        />
      </Container>
    </React.Fragment>
  );
};
