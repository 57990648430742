import { TableColumn } from 'react-data-table-component';

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
}

// eslint-disable-next-line no-shadow
export enum FormInputFilter {
  filterSearch = 'filterSearch',
  inputSearch = 'inputSearch',
}

export interface DataRow {
  id: string;
  client: string;
  document: string;
  protocol: string;
  chatDate: string;
  type: string;
  status: string;
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Cliente',
    selector: row => row.client,
  },
  {
    name: 'CPF',
    selector: row => row.document,
  },
  {
    name: 'Protocolo',
    selector: row => row.protocol,
  },
  {
    name: 'Abertura',
    selector: row => row.chatDate,
  },
  {
    name: 'Tipo',
    selector: row => row.type,
  },
  {
    name: 'Status',
    selector: row => row.status,
  },
];

export type UrlParams = {
  id: string;
};

// eslint-disable-next-line no-shadow
export enum FormInputMessagem {
  message = 'message',
  file = 'file',
}

// eslint-disable-next-line no-shadow
export enum FormUpdateSupportStatus {
  status = 'status',
}
