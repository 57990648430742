import React, { useEffect, useState } from 'react';
import { ProducerReport } from '@/model/ProducerReport';
import api, { AxiosError } from '@/services/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import { unmask, updateMask } from '@/helpers/masks/cashNumber';
import Contractor from '@/model/Contractor';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { EventMoneyTransfer } from '@/model/EventMoneyTransfer';
import { PixKey } from '@/model/PixKey';
import { FormInput, ShouldShowModal, States, UrlParams } from '../types';
import { ProducerReportContainer } from './ui';

export const ProducerReportScreen: React.FC = (): JSX.Element => {
  const params = useParams<UrlParams>();
  const [state, setState] = useState<States>(States.default);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.transfer);
  const [producerReport, setProducerReport] = useState<ProducerReport>();
  const [transfers, setTransfers] = useState<EventMoneyTransfer[]>([]);
  const [contractors, setContractors] = useState<Contractor[]>([]);
  const { visible, title, onChangeTitle, onToggle } = useDialog();

  const handleOnFetch = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<ProducerReport>(`/event/report/${params.id}/producer`);
      if (data) {
        setProducerReport(data);
      }
      const response = await api.get<EventMoneyTransfer[]>(
        `/event-money-transfer/find/${params.id}`,
      );
      if (response && response.data) {
        setTransfers(response.data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
    setState(States.default);
  };

  const { formData, formErrors, onChangeFormInput, isFormValid, resetForm } = useForm({
    initialData: {
      contractor: '',
      pixKey: '',
      transferValue: '',
    },
    validators: {
      contractor: [validators.required],
      pixKey: [validators.required],
      transferValue: [validators.required],
    },
    formatters: {
      transferValue: updateMask,
    },
  });

  const handleOnTransfer = async (): Promise<void> => {
    if (isFormValid()) {
      const transferValue = Number(unmask(formData[FormInput.transferValue]));
      if (!producerReport || Number(producerReport.summary.balance) < transferValue) {
        const msg = `Valor disponível (${toCurrency(
          producerReport ? producerReport.summary.balance : 0,
        )}) menor que o valor solicitado (${toCurrency(transferValue)})`;
        toast.error(msg);
      } else {
        try {
          setState(States.loading);
          const payload = {
            contractor: { id: formData[FormInput.contractor] } as Contractor,
            pixKey: { id: formData[FormInput.pixKey] } as PixKey,
            transferValue,
          } as EventMoneyTransfer;
          await api.post(`/event-money-transfer/${params.id}`, payload);
          await handleOnFetch();
          resetForm();
          setContractors([]);
          onToggle();
        } catch (error) {
          const err = error as AxiosError;
          toast.error(err.message);
        } finally {
          setState(States.default);
        }
      }
    }
  };

  const handleOnGetContractors = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Contractor[]>(`/event-money-transfer/contractor/${params.id}`);
      setContractors(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
  }): Promise<void> => {
    if (value === ShouldShowModal.transfer) {
      await handleOnGetContractors();
    }
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  useEffect(() => {
    handleOnFetch();
  }, []);

  return (
    <ProducerReportContainer
      state={state}
      producerReport={producerReport}
      transfers={transfers}
      visible={visible}
      title={title}
      onToggle={onToggle}
      formData={formData}
      formErrors={formErrors}
      onChangeFormInput={onChangeFormInput}
      shouldShowModal={shouldShowModal}
      contractors={contractors}
      onTransfer={handleOnTransfer}
      onShouldShowModal={handleOnShouldShowModal}
    />
  );
};
